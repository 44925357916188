import React from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

const apiUrl = "https://backend-cagueloup.vercel.app";

const CheckoutForm = ({ clientSecret, orderId, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const updateOrderPaymentStatus = async (orderId) => {
    if (!orderId) {
      console.error("L'ID de la commande est manquant !");
      return false;
    }

    try {
      const response = await fetch(`${apiUrl}/api/orders/${orderId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ paymentStatus: "paid" }),
      });

      if (!response.ok) {
        console.error("Erreur lors de la mise à jour de la commande :", await response.json());
        return false;
      } else {
        const data = await response.json();
        console.log('Commande mise à jour:', data);
      }
      console.log("Commande mise à jour avec succès !");
      return true;
    } catch (error) {
      console.error("Erreur réseau :", error);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js n'est pas encore chargé.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (error) {
        console.error("Erreur lors du paiement :", error.message);
        return;
      }

      if (paymentIntent.status === "succeeded") {
        console.log("Paiement réussi :", paymentIntent);
      
        try {
          const isUpdated = await updateOrderPaymentStatus(orderId);
          if (isUpdated) {
            onSuccess(); // Appelle la fonction après succès
          } else {
            console.error("Erreur lors de la mise à jour de l'état du paiement.");
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour de la commande :", error);
        }
      }
      
    } catch (err) {
      console.error("Erreur inattendue lors du paiement :", err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="formStripe">
      <p>Veuillez rentrer vos informations banquaires ci dessous :</p>
      <CardElement />
      <div className="containerButtonStripe">
        <button className="buttonToStripe" type="submit" disabled={!stripe || !elements}>
          Commander
        </button>
      </div>
    </form>
  );
};


export default CheckoutForm;