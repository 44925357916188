import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../payments/CheckoutForm.jsx";
import { useTranslation } from "react-i18next";
import Decimal from "decimal.js";

const apiUrl = "https://backend-cagueloup.vercel.app"; 

const stripePromise = loadStripe("pk_live_51QXNLnJQVo6OimaL4WllECI6ppgLqtf8rUYd2ij74c4t9N2SuJNeg9r8cDf7jjcU5NxG4l34Y5ztFyBryBiSLUy600Em89pNC9");

const WinePage = ({ selectedWine, onClose }) => {
  const [step, setStep] = useState(1); 
  const [quantity, setQuantity] = useState(1); 
  const [clientSecret, setClientSecret] = useState(null); 
  const { t } = useTranslation();
  const [orderId, setOrderId] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    addressSuite: "",
    doorCode: "",
    postalCode: "",
    city: "",
    countryCode: "FR",
    phone: "",
    email: "",
    relayPoint: "",
  });

  console.log("Component WinePage rendered");

  const calculateAmounts = (qty) => {
    const priceHTInCents = new Decimal(qty).times(selectedWine.price).times(100); 
    const deliveryFeesInCents = new Decimal(qty).times(290); 
    const totalTTCInCents = priceHTInCents.plus(deliveryFeesInCents); 

    console.log("Calcul des montants:", {
      priceHT: priceHTInCents.toNumber(),
      deliveryFees: deliveryFeesInCents.toNumber(),
      totalTTC: totalTTCInCents.toNumber(),
    });

    return {
      priceHT: priceHTInCents.div(100).toNumber(), 
      deliveryFees: deliveryFeesInCents.div(100).toNumber(),
      totalTTC: totalTTCInCents.div(100).toNumber(),
    };
  };

  const { priceHT, deliveryFees, totalTTC } = calculateAmounts(quantity);

  // Gestion des changements dans le formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input change: ${name} = ${value}`);

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Validation du formulaire
  const validateForm = () => {
    const { firstName, lastName, address, postalCode, city, phone, email } = formData;
    const isValid = (
      firstName &&
      lastName &&
      address &&
      postalCode &&
      city &&
      phone &&
      email &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) &&
      /^[\d\s\-+()]*$/.test(phone)
    );
    console.log("Validation du formulaire:", isValid ? "Valide" : "Invalide", formData);
    return isValid;
  };

  // Gestion du changement de quantité
  const handleQuantityChange = (e) => {
    const newQty = Math.max(1, parseInt(e.target.value, 10) || 1);
    console.log("Quantité changée:", newQty);
    setQuantity(newQty);
  };  
  const [loading, setLoading] = useState(false);

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => Math.min(prevQuantity + 1, 100));
    console.log("Quantité augmentée:", quantity + 1);

  };
  
  const decrementQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
    console.log("Quantité diminuée:", quantity - 1);
  };
  

  const handlePayment = async () => {
    setLoading(true);
    console.log("Début de handlePayment");

    try {
      const now = new Date();
      const shipmentDate = new Date(now.getTime() - now.getMilliseconds());
      if (shipmentDate < new Date()) {
        shipmentDate.setTime(shipmentDate.getTime() + 60 * 1000); 
      }
      const shipmentDateISOString = shipmentDate.toISOString();
  
      const bottleWeight = 0.75; 
      const totalWeight = bottleWeight * quantity; 
  
      console.log("Détails de la commande:", {
        quantity,
        totalWeight,
        shipmentDate: shipmentDateISOString,
      });
      // Création de la commande
      const orderResponse = await fetch(`${apiUrl}/api/orders`, {
      
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lastName: formData.lastName,
          firstName: formData.firstName, 
          email: formData.email,
          address: formData.address,
          addressSuite:  formData.addressSuite,
          doorCode:  formData.doorCode,
          postalCode:  formData.postalCode || "",
          city:  formData.city,
          countryCode: formData.countryCode,
          phone: formData.phone,
          relayPoint: formData.relayPoint || "",
          products: [
            {
              productId: selectedWine.id,
              quantity,
              price: selectedWine.price,
            },
          ],
          totalAmount: totalTTC,
          weight: totalWeight,
          shipmentDate: shipmentDateISOString,
          paymentStatus: "unpaid",
          documentType: "2",
          type: 'part',
          productCode: "9A",
          saturdayDelivery : "2",
          contentDescription: t(selectedWine.title_key),
          nomEnvoi: "domaine du cagueloup",
        }),
      });

      if (!orderResponse.ok) {
        const error = await orderResponse.json();
        console.error("Erreur lors de la création de la commande:", error.message);
        setStep(1); // Revenir à l'étape précédente
        alert(`Erreur : ${error.message || "Création de la commande échouée"}`);
        return;
    }    
  
      const orderData = await orderResponse.json();
      console.log("Commande créée avec succès:", orderData);
      setOrderId(orderData._id);
  
      // Demande du paiement Stripe
      const response = await fetch(`${apiUrl}/api/payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: Math.round(totalTTC * 100),
          currency: "eur",
          orderId: orderData._id,
        }),
      });
  
      const data = await response.json();
      if (response.ok) {
        console.log("Client secret Stripe reçu:", data.clientSecret);
        setClientSecret(data.clientSecret);
        setStep(3);
      } else {
        console.error("Erreur de paiement :", data.error);
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleSuccess = () => {
    console.log("Paiement réussi !");
    setStep(4);
  };

  return (
    <div className="modal">
      <button className="closeButton" onClick={onClose}>
        Fermer
      </button>

      {step === 1 && (
        <div className="selectionWineNumber">
          <div >
            <h3 className="formTitle">Achat de {t(selectedWine.title_key)}</h3>
          </div>
          <div className="formSelectionWine">
          <label>
            Nombre de bouteilles :
            <div className="inputNumberWine">
              <input
                className="numberWine"
                type="number"
                value={quantity}
                min="1"
                max="100"
                onChange={handleQuantityChange}
              />
            <div className="wineNumberControl">
              <button
                aria-label="Decrement number"
                className="down-icon"
                tabIndex="-1"
                title="Decrement number"
                type="button"
                onClick={decrementQuantity}
              >
                <svg
                  focusable="false"
                  preserveAspectRatio="xMidYMid meet"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                  className="updown"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 15H24V17H8z"></path>
                </svg>
              </button>
              <button
                aria-label="Increment number"
                className="up-icon"
                tabIndex="-1"
                title="Increment number"
                type="button"
                onClick={incrementQuantity}
                >
                <svg
                  focusable="false"
                  preserveAspectRatio="xMidYMid meet"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                  className="updown"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <path d="M17 15L17 8 15 8 15 15 8 15 8 17 15 17 15 24 17 24 17 17 24 17 24 15z"></path>
                </svg>
              </button>
            </div>
            </div>
          </label>
          <div className="infoAchat">
            <p>Prix HT : {priceHT.toFixed(2)} €</p>
            <p>Frais de livraison : {deliveryFees.toFixed(2)} €</p>
            <p>Total TTC : {totalTTC.toFixed(2)} €</p>
            <button className="buttonContinue" onClick={() => setStep(2)}>Continuer</button>
          </div>
        </div>
        </div>
      )}

      {step === 2 && (
        <div className="formClient">
          <h2 className="formTitle">Informations de livraison</h2>
          <form className="informationsClient">
            <label className="infoClientLabel">
              Prénom :
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Nom :
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Adresse :
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Suite de l'adresse :
              <input
                placeholder="facultatif"
                type="text"
                name="addressSuite"
                value={formData.addressSuite}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Code Porte :
              <input
                placeholder="facultatif"
                type="text"
                name="doorCode"
                value={formData.doorCode}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Ou point Relais :
              <input
                placeholder="facultatif"
                type="text"
                name="relayPoint"
                value={formData.relayPoint}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Code postal :
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Ville :
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Téléphone :
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Email :
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </label>
            <label className="infoClientLabel">
              Pays :
              <input
                type="text"
                name="countryCode"
                value={formData.countryCode}
                disabled
              />
            </label>
            <button 
            className="buttonToStripe"
            disabled={loading} 
            onClick={validateForm() ? handlePayment : () => alert("Veuillez compléter tous les champs obligatoires !")}
          >
            {loading ? "Chargement..." : "Payer"}
          </button>
          </form>
          
        </div>
      )}

      {step === 3 && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm 
            clientSecret={clientSecret}
            orderId={orderId} 
            onSuccess={handleSuccess} 
          />
        </Elements>
      )}
      {step === 4 && (
        <div className="paymentSuccess">
          <h3>Paiement réussi !</h3>
          <p>
            Merci pour votre achat {t(selectedWine.title_key)} x{quantity} à {new Date().toLocaleDateString()}.
          </p>
          <p>
          Vous recevrez un email à l'adresse : {formData.email} de la part du domainedecagueloup@gmail.com lorsque la commande sera expédiée.
          </p>
          <p>Nous vous remercions pour votre confiance et vous souhaitons une excellente dégustation !</p>
        </div>
      )}
    </div>
  );
};

export default WinePage;