import React, { useState, useEffect } from "react";
import Carrousel from "../../component/carroussel";
import { useTranslation } from "react-i18next";
import Info from "../../component/modales/info";
import WinePage from "../../component/modales/WinePage.jsx";

function Wine({ onClose }) {
  const { t } = useTranslation();
  const [selectedWine, setSelectedWine] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showWinePage, setShowWinePage] = useState(false); // Gère la modale WinePage

  const handleSelectWine = (wine) => {
    setSelectedWine(wine);
    setShowInfo(true); 
  };

  const handleToggleInfo = () => {
    setShowInfo((prevShowInfo) => !prevShowInfo); 
  };

  const handleCloseWinePage = () => {
    setShowWinePage(false); // Ferme la modale WinePage
  };

  useEffect(() => {
    setShowInfo(false);
  }, []);

  return (
    <>
      <div className="modal-wine">
        <div className="wine">
          <Carrousel 
            onSelect={handleSelectWine} 
            onToggleInfo={handleToggleInfo} 
            showInfo={showInfo} 
          />
          {showInfo && selectedWine && <Info wine={selectedWine} />}
        </div>
        <div className="close-modal">
          <button onClick={onClose}>
            <h5>{t("button_close")}</h5>
          </button>
          <button 
            onClick={() => setShowWinePage(true)} 
            disabled={!selectedWine}
          >
            <h5 className="buttonBuy">{t("button_buy")}</h5>
          </button>
        </div>
      </div>

      {/* Modale WinePage */}
      {showWinePage && selectedWine && ( // Vérifie que selectedWine est défini
        <div className="modal-overlay">
          <WinePage 
            selectedWine={selectedWine} // Passe l'objet vin ici
            onClose={handleCloseWinePage} 
          />
        </div>
      )}
    </>
  );
}

export default Wine;
